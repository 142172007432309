@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body{
    margin: 0;
    padding:0;
    font-family: "Open Sans", sans-serif;
}

.remove-button {
    display: none;
}

@layer base {
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button,
    input[type='number'] {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield !important;
    }
}
  


@tailwind base;
@tailwind components;
@tailwind utilities;
